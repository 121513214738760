import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useGetUserByIdQuery } from "../../store/apiSlice";
export default function JobPostCredits({ employerDetails = {} }) {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { data } = useGetUserByIdQuery({ id: userInfo.id });
  //if (data) { jobPostCreditCount = data?.task.jobCredits }
  const jobPostCreditCount = useSelector((state) => state.posts.jobCredits);
  const expiryDate = "1 April 2025";

  return (
    <div className="w-full sm:max-w-[260px] bg-slate-100 p-4 rounded-lg">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <span className="font-bold text-amber-500">
            {jobPostCreditCount === -1 ? "Unlimited" : jobPostCreditCount}
          </span>
          {jobPostCreditCount === 1 ? " Job Credit" : " Job Credits"}
        </div>
          <Link to="/choose-job-pack">
            <button className="btn-circle btn-xs bg-green-500 hover:bg-gray-600 text-white rounded-full">
              +
            </button>
          </Link>
      </div>
      {jobPostCreditCount === -1 ? (
        <div>
          {/* <p className="m-0 mt-2 text-center">
            <span className="font-bold">Plan expires on: </span>
            {expiryDate}
          </p> */}
        </div>
      ) : null}
    </div>
  );
}
