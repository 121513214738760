import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { useUpdatePostRegionsMutation } from "../store/apiSlice";

const RegionalPosting = ({ postRegions, id }) => {
  const [updatePostRegions] = useUpdatePostRegionsMutation();
  const [selectedRegions, setSelectedRegions] = useState(postRegions || []);

  useEffect(() => {
    setSelectedRegions(postRegions || []);
  }, [postRegions]);

  const handleSelectChange = (selectedOptions) => {
    const updatedRegions = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedRegions(updatedRegions);
    updatePostRegions({ id, postRegions: updatedRegions });
  };

  const options = [
    { value: "Canada", label: "Canada" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
  ];

  return (
    <div className="flex flex-col gap-0 mb-3">
      <div className="text-sm text-sky-600">Instantly post your job internationally:</div>
      <Select
        isMulti
        value={options.filter(option => selectedRegions.includes(option.value))}
        onChange={handleSelectChange}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>
  );
};

export default RegionalPosting;
