import React from "react";

export default function SignUpUnis() {
  return (
    <section
      title="Customer Logos"
      className="container mx-auto flex flex-col mt-16 gap-8"
    >
      <h3 className="text-slate-600 text-center mb-10 font-bold">
        Thousands of top universities hire on Academic Jobs, including...
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-8 justify-items-center items-center">
        <div>
          <img
            src="/university-logos/usa/harvard-university.png"
            width={300}
            height={50}
            alt="University Logo"
          />
        </div>
        <div>
          <img
            src="/university-logos/usa/california-institute-of-technology-caltech.jpg"
            width={300}
            height={50}
            alt="University Logo"
          />
        </div>
        <div>
          <img
            src="/university-logos/usa/stanford-university.png"
            width={300}
            height={50}
            alt="University Logo"
          />
        </div>
        <div>
          <img
            src="/university-logos/usa/massachusetts-institute-of-technology-mit.png"
            width={300}
            height={50}
            alt="University Logo"
          />
        </div>
        <div>
          <img
            src="/university-logos/misc/oxford-university.jpg"
            width={200}
            height={40}
            alt="University Logo"
          />
        </div>
        <div>
          <img
            src="/university-logos/misc/cambridge_at_academicjobs_logo.jpg"
            width={130}
            height={50}
            alt="University Logo"
          />
        </div>
        <div>
          <img
            src="/university-logos/misc/nus_academic_jobs_logo.jpg"
            width={300}
            height={50}
            alt="University Logo"
          />
        </div>
        <div>
          <img
            src="/university-logos/misc/eth-zurich-academic-jobs-logo.jpg"
            width={300}
            height={50}
            alt="University Logo"
          />
        </div>
      </div>
    </section>
  );
}
