// src/DashBoard.js
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetJobs2Query } from "../store/apiSlice";
import CountUp from 'react-countup';

const DashBoard = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [q] = useState({ userId: -1, employer_id: userInfo?.employer_id || -1 });
  const { data, error, isLoading } = useGetJobs2Query({ ...q });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl font-semibold">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-center">
          <p className="text-lg">No job data to load, start posting now <a className="text-amber-500 hover:text-gray-500 underline" href="/post-job-employer">here</a>!</p>
        </div>
      </div>
    );
  }

  const totalJobs = data?.length || 0;
  const totalHeadlineJobs = data?.filter(job => job.headlineOnly === 1).length || 0;
  const totalFeaturedJobs = data?.filter(job => job.featured === 1).length || 0;

  return (
    <div className="px-4">
      <h2 className="text-gray-500 font-bold text-4xl mt-8">Job Stats</h2>
      <div className="grid grid-cols-1 gap-6 mt-8 sm:grid-cols-3">
        <Card title="Total Jobs" count={totalJobs} />
        <Card title="Headline Jobs" count={totalHeadlineJobs} />
        <Card title="Featured Jobs" count={totalFeaturedJobs} />
      </div>

      <div className="mt-24">
        <h2 className="text-gray-500 font-bold text-4xl mb-8">Our Audience</h2>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
          <InfoCard
            title="Fastest Growing Job Board on Google"
            description="Purpose built for google optimization for higher education jobs and all universities"
            imgSrc="./employer-dashboard/google.png"
            borderColor={"border-[#FFA500]"}
            width={200}
            height={100}
            textSize={"text-sm"}

          />
          <InfoCard
            title="#1 for Indigenous Academic Jobs"
            description="Academic Jobs is the only Jobs Board Globally that is dedicated to Indigenous Academic Jobs. The largest network of indigenous job seekers in higher education, with 1,200. Sign up to receive job alerts"
            imgSrc="./employer-dashboard/indigenous.png"
            borderColor={"border-[#FF76B8]"}
            width={200}
            height={100}
            textSize={"text-sm"}


          />
          <InfoCard
            title="Social Reach"
            description="1.2 million +"
            imgSrc="./employer-dashboard/socials.png"
            borderColor={"border-[#FF828A]"}
            width={500}
            height={100}
            textSize={"text-6xl"}
          />
        </div>
        <div className="grid grid-cols-1 gap-6 mt-8 sm:grid-cols-3">
          <StatCard title="Average job views for featured jobs" stat="63" borderColor={"border-[#FFA500]"} />
          <StatCard title="Average apply now hits for featured jobs" stat="14.9" borderColor={"border-[#FF76B8]"} />
          <StatCard title="Monthly job alerts" stat="541,632" subtext="3000% growth over 3 months" borderColor={"border-[#FF828A]"} />
        </div>
        <div className="flex items-start mt-24">
          <h2 className="text-gray-500 font-bold text-4xl flex items-end">Your Employee Referral Platform</h2>
          <img src="./employer-dashboard/referral.png" alt="academic connect" className="lg:block hidden rounded-lg h-auto w-[200px] ml-4" style={{ transform: 'translateY(-25%)' }} />
        </div>
        <div class="max-w-6xl font-medium mx-auto text-center mt-8">
          <p class="leading-relaxed text-base">
            Referrals are 5X more likely to be hired. The 'Academic Connection' is proven to secure better new colleagues for a happier, more productive workplace. It promotes your Employer Brand for future applicant connections. Make use of your professional network and our technology, and you get a 45% more chance of a highly desirable hire for your team.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row items-center relative w-full">
          <div className="w-3/4 sm:w-[40%] rounded-lg sm:-mr-16 z-30">
            <img src="./employer-dashboard/hr-recruiter.png" alt="academic connect" className="sm:block hidden rounded-lg w-full" />
            <img src="./employer-dashboard/hr-recruiter-mobile.png" alt="academic connect" className="sm:hidden block rounded-lg w-full" />

          </div>
          <div className="w-3/4 sm:w-[40%] rounded-lg sm:-mr-24 z-20">
            <img src="./employer-dashboard/brand-ambassador.png" alt="academic connect" className="sm:block hidden rounded-lg w-full" />
            <img src="./employer-dashboard/brand-ambassador-mobile.png" alt="academic connect" className="sm:hidden block rounded-lg w-full" />

          </div>
          <div className="w-3/4 sm:w-[40%] rounded-lg z-10">
            <img src="./employer-dashboard/network.png" alt="academic connect" className="sm:block hidden rounded-lg w-full" />
            <img src="./employer-dashboard/network-mobile.png" alt="academic connect" className="sm:hidden block rounded-lg w-full" />

          </div>
        </div>


      </div>

      {/* <div className="hidden mt-24 md:flex">
        <img
          src="/ajteambanner.png"
          alt="academic jobs"
          className="w-2/3 rounded-lg" />
        <div className="w-1/3 p-2">
          <div className="bg-gray-200 rounded-lg p-4 mb-1">
            <span className="font-bold ">Any Questions? <br /> Contact us at: <br />
              <a href="tel:+61430017783" className="text-blue-600 hover:underline">+61 430 017 783</a> or <a href="mailto:jarrod@academicjobs.com" className="text-blue-600 hover:underline">jarrod@academicjobs.com</a></span>
          </div>
          <div className="bg-gray-200 rounded-lg p-4">
            <h1 className="text-amber-500 font-bold italic">"With Our Technology, Post Your Jobs in Just 32 Seconds!" </h1>
          </div>
        </div>
      </div> */}
    </div>
  );
};

const Card = ({ title, count }) => (
  <div className="border border-gray-300 rounded-lg p-6 text-center shadow-lg">
    <h2 className="text-2xl font-semibold mb-4">{title}</h2>
    <p className="text-4xl font-bold">
      <CountUp end={count} duration={2.5} />
    </p>
  </div>
);

const InfoCard = ({ title, description, imgSrc, secondImg, borderColor, width, height, textSize }) => (
  <div className={`border-4 ${borderColor} rounded-xl p-6 text-center shadow-lg`}>
    <h3 className="text-2xl text-black font-semibold">{title}</h3>
    <div className="flex justify-center">
      <img src={imgSrc} alt={title} className="mx-auto" width={width} height={height} />
    </div>
    <p className={`text-gray-700 ${textSize}`}>{description}</p>
    {secondImg && <img src={secondImg} alt={title} className="" />}
  </div>
);

const StatCard = ({ title, stat, subtext, borderColor }) => (
  <div className={`border-2 ${borderColor} rounded-xl p-6 text-center shadow-lg`}>
    <h3 className="text-xl text-black font-semibold mb-2">{title}</h3>
    <p className="text-5xl font-bold mb-2">{stat}</p>
    {subtext && <p className="text-sm text-gray-700">{subtext}</p>}
  </div>
);

export default DashBoard;
