import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import SearchResults2 from "./SearchResults2";
// import { useGetOrderedProductsQuery } from "../store/apiSlice";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { setJobCredits } from "../store/postsSlice";
import UniSearchBlock1 from "./UniSearchBlock1";
import { useForm } from "react-hook-form";
import EditEmployerProfile1 from "./EditEmployerProfile1";
import SalesLeadsList from "./SalesLeadsList";
import { setJob, setId, setEmployer } from "../store/postsSlice";
import SearchResultsEmployers from "./SearchResultsEmployers";
import AppContextTitleAndQuote from "../components/profile/AppContextTitleAndQuote";
import EmployerStats from "../components/profile/EmployerStats";
function EmployersCenter(props) {
  const { id } = useParams();
  const employer = useSelector((state) => state.posts.employer);
  //alert(employer.employer_id)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employerID, setEmployerID] = useState(employer?.employer_id || 7);
  const region = useSelector((state) => state.posts.region);
  const userInfo = useSelector((state) => state.auth.userInfo);
  // const { data, isLoading, isSuccess } = useGetOrderedProductsQuery({ id: userInfo.id });
  let content, content1;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  useEffect(() => {
    register("employer_name", { required: false });
  }, [register]);
  let { expired } = useParams();
  const onEditorStateChange1 = (company_name, id, Region, country) => {

    setEmployerID(id);
    dispatch(setEmployer({ company_name, employer_id: id }));
    //setValue("description", editorState);
  };
  const onSelectUniSearch = (company_name, id, Region, country) => {

    setEmployerID(id);
    dispatch(setEmployer({ company_name, employer_id: id }));
    navigate(`/employers-center/${id}/`);
    //setValue("description", editorState);
  };
  return (
    <div className="w-full flex  flex-col px-6  gap-8 ">
      {/* <div className="flex max-w-screen-xl gap-2 border p-2 bg-gray-200 rounded">
      <UniSearchBlock1
            country=""
            register={register}
            field="employer_name"
            customKey="Search all employers"
            label="Search all employers"
            value1=""
            forceClass="input-lg mx-0"
            onChange={onEditorStateChange1}
            onSelect={onSelectUniSearch}
          />
      </div> */}
      {/* <AppContextTitleAndQuoteAAAAA
        title="Organisation Profile"
        employerId={userInfo.employer_id}
      /> */}
      <p className="text-3xl font-bold text-black shadow-xl px-2 pb-4 mt-4 mb-6">Organistion Profile</p>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="md:w-1/2 w-full">
          <EditEmployerProfile1 id={employer?.employer_id} />

          <SalesLeadsList id={employer?.employer_id} />
          {/* {employer?.company_name && (
            <div className="flex flex-col gap-2 p-4">
              <div className=" flex justify-start gap-1 items-center align-middle">
                <ul className=" flex justify-start items-end gap-4   px-1  ">
                  <div className="card w-[100px] h-[100px] bg-base-100 shadow-xl ">
                    <div className="w-[100px] h-[100px] grid place-items-center">
                      <img
                        src={employer?.logo ? `https://academicjobs.s3.amazonaws.com/img/university-logo/${employer?.logo}` : '/favicon.png'}
                        alt={`${employer?.company_name}`}
                        className="object-contain rounded-md bg-white"
                      />
                    </div>
                  </div>
                </ul>
                <div className="text-4xl text-orange-600 font-black text-center ">{employer?.company_name}</div>
              </div>
              <div className="text-2xl text-orange-600 font-black text-left ">Employer Profile</div>
              <div>
                <Link
                  to={`/employers/${employer?.company_name
                    ?.replace(/\W+/g, "-")
                    .toLowerCase()}/${employer?.employer_id}/`}
                  className="btn w-[49%]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Employer Profile
                </Link>
                <Link
                  to={employer?.website}
                  className="btn w-[49%] ml-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Employer Job Page URL
                </Link>
              </div>
              <Link to={`/post-a-job/`}
                className="btn"
              >Post A Job</Link>
            </div>
          )}
          <EditEmployerProfile1 id={employer?.employer_id} /> */}
        </div>
        <div className=" md:w-1/2 w-full">
          <div className="bg-slate-100 p-4 rounded-lg">
            <EmployerStats organisation={userInfo.orgnization_name} />
          </div>
          {/* <div className="flex bg-slate-100 rounded-t-xl p-4 gap-8 justify-between">
            <h2 className="font-bold">Job Statistics</h2>
            <h2 className="font-bold text-amber-500">
              Job Credits = 1 / 5 / 10
            </h2>
          </div>
          <div className=" bg-slate-100 rounded-b-xl px-4 pb-4">
            <h2 className="font-bold">Likes: 0</h2>
            <h2 className="font-bold">Apply Now Hits: 0</h2>
            <h2 className="font-bold">Job Views: 0</h2>
            <h2 className="font-bold">Talent Pool: 0</h2>
          </div>
              */}
          <SearchResults2
            q={{ employer_id: employer?.employer_id || 0, expired: "0" }}
          />
        </div>
      </div>
    </div>
  );
}
export default EmployersCenter;
