import React, { useState } from 'react';
import axios from 'axios';
import JobKeywordSearchBlock from "../views/JobKeywordSearchBlock";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from 'react-router-dom';
import { useMemo } from "react";

const validationSchema = yup.object({}).required();

const AcademicLeadJobs = ({ jobs = [] }) => {

  function generateIndigenousEmail(id, title, company_name, firstName) {
    const formattedTitle = title.replace(/ /g, '-').replace(/\//g, '-');
    const emailTemplateIndigenous =
      `<!DOCTYPE html>
      <html>
      <body style="font-family: Arial, sans-serif; margin-top: 50px;">
          <div style="width: 80%; max-width: 800px; margin: auto; padding: 20px; border-radius: 5px;">
              <p>Hello ${firstName},</p>
              <p>Exciting news… The Australian Indigenous Academics Network Is finally here.</p>
              <p>Your participation will assist in creating a rich tapestry of knowledge and experience in higher education,
                  and add to the vibrant community that we are creating.</p>
              <p>An account has been created for you to join AIAN! Sign in <a href="https://www.academicjobs.com/auth/signin" rel="noopener noreferrer" target="_blank">here</a> with your email and the password provided below:</p>
              <b>password:</b> dfxg9CIqip
              <p><br></p>
              <p>Part of the function of the network is to bring you Academic conferences and job opportunities, here’s our
                  first ‘Indigenous Job of the week’:</p>
              • <a href="https://www.academicjobs.com/jobs/${formattedTitle}/${id}"
              rel="noopener noreferrer" target="_blank">${company_name}: ${title}</a>
              <p>Also there are currently 16 ‘Indigenous Studies’ jobs in Australia, with another 980 university jobs open to
                  all indigenous people, see them <a href="https://www.academicjobs.com/australia/indigenous" rel="noopener noreferrer" target="_blank">here</a>.
              <p><br></p>
              <p>Looking forward to your active participation!</p>
              <p>Best regards,</p>
              <p>Jarrod</p>
              <p><img src="https://academicjobs.s3.amazonaws.com/img/_misc/jarrod-email.jpg" height="218" width="434" alt="Jarrod"></p>
              <p><br></p>
          </div>
      </body>
      
      </html>
      `
    return emailTemplateIndigenous;
  }

  function generateHREmail(id, title, company_name, firstName) {
    const formattedTitle = title.replace(/ /g, '-').replace(/\//g, '-');
    const emailTemplateHR =
      `<!DOCTYPE html>
      <html>
      <body style="font-family: Arial, sans-serif; margin-top: 50px;">
        <div style="width: 80%; max-width: 800px; margin: auto; padding: 20px; border-radius: 5px;">
            <p>Hello ${firstName},</p>
            <p>Exciting news… The Higher Ed HR Network is finally here.</p>
            <p>Your participation will help create an insightful HR experience and contribute to the vibrant community that
                we are building.</p>
            <p>An account has been created for you to join THE HR NETWORK with your email and the password provided below, sign in <a href="https://www.academicjobs.com/auth/signin" rel="noopener noreferrer" target="_blank">here</a> 
            </p>
            <b>password:</b> dfxg9CIqip
            <br></p></p> 
            <p>Part of the function of the network is to bring you relevant Higher Ed conferences and HR job opportunities,
                here’s our first ‘HR Job of the week’:</p>
            • <a href="https://www.academicjobs.com/jobs/${formattedTitle}/${id}" rel="noopener noreferrer"
                    target="_blank">${company_name}: ${title}</a> </p>
            <p>Also, there are currently 9 ‘HR’ jobs in Australia, see them <a
                    href="https://www.academicjobs.com/australia/hr-jobs" rel="noopener noreferrer"
                    target="_blank">here</a></p>
            <p><br></p>
            <p>Looking forward to your active participation!</p>
            <p>Best regards,</p>
            <p>Jarrod</p>
            <p><img src="https://academicjobs.s3.amazonaws.com/img/_misc/jarrod-email-signature-HR.png" height="218" width="434" alt="Jarrod"></p>
        </div>
      </body>
      </html>
      `;
    return emailTemplateHR;
  }

  function generateGenericEmail(id, title, company_name, firstName) {
    const formattedTitle = title.replace(/ /g, '-').replace(/\//g, '-');
    const emailTemplateGeneric =
      `<!DOCTYPE html>
      <html>
      <body style="font-family: Arial, sans-serif; margin-top: 50px;">
          <div style="width: 80%; max-width: 800px; margin: auto; padding: 20px; border-radius: 5px;">
            <p>Hello ${firstName},</p>
            <p><br></p>
            <p>I hope this email finds you well.</p>
            <p><br></p>
            <p>We are excited to inform you about a job opportunity that aligns perfectly with your skills and experience.
                ${company_name} is currently seeking to fill the position of ${title}. Based on your profile and
                background, we believe this role could be an excellent fit for you.</p>
            <p><br></p>
            <p>We encourage you to review the full job description and consider applying for this position. You can find more
                details and submit your application through the following link:  <a href="https://www.academicjobs.com/jobs/${formattedTitle}/${id}"
                rel="noopener noreferrer" target="_blank">${company_name}: ${title}</a></p>
            <p><br></p>
            <p>Also, an account has been created for you to join our network with your email and the password provided below, sign in <a href="https://www.academicjobs.com/auth/signin" rel="noopener noreferrer" target="_blank">here</a> 
            </p>
            <b>password:</b> dfxg9CIqip
            <p>Best regards,</p>
            <p>Jarrod</p>
            <p><img src="https://academicjobs.s3.amazonaws.com/img/_misc/jarrod-email-signature-generic.png" height="218" width="434" alt="Jarrod"></p>
        </div>
      </body>
      </html>
      `;
    return emailTemplateGeneric;
  }

  const [selectedJob, setSelectedJob] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    keyword: '', // New field for the keyword
    indigenousAcademic: false,
  });

  const handleOpenForm = (job) => {
    setSelectedJob(job);
  };

  const handleCloseForm = () => {
    setSelectedJob(null);
    setFormData({ firstName: '', lastName: '', email: '', keyword: '', indigenousAcademic: false });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  function generateEmail(selectedJob, formData) {
    if (selectedJob.master_category_job_type === 'Academic / Faculty' && selectedJob.thirdcategory === 'Indigenous Studies') {
      return generateIndigenousEmail(selectedJob.id, selectedJob.title, selectedJob.company_name1, formData.firstName);
    } else if (selectedJob.master_category_job_type === 'Human Resources') {
      return generateHREmail(selectedJob.id, selectedJob.title, selectedJob.company_name1, formData.firstName);
    } else {
      return generateGenericEmail(selectedJob.id, selectedJob.title, selectedJob.company_name1, formData.firstName);
    }
  }

  function getSubject(selectedJob) {
    if (selectedJob.master_category_job_type === 'Academic / Faculty' && selectedJob.thirdcategory === 'Indigenous Studies') {
      return "The Indigenous Academics Network - Your Invite";
    } else if (selectedJob.master_category_job_type === 'Human Resources') {
      return "The Higher Ed HR Network - Your Invite";
    } else {
      return "Academic Jobs | This Job Matches Your Skillset!";
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('https://api2.sciencejobs.com.au/api/shareJobAcademicLead', {
        ...formData,
        job: selectedJob,
        subject: getSubject(selectedJob),
        data: generateEmail(selectedJob, formData)
      });
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }

    try {
      await axios.post('https://api2.sciencejobs.com.au/api/addUser', {
        ...formData,
        indigenousAcademic: formData.indigenousAcademic,
      });
      console.error('User added successfully!');
    } catch (error) {
      console.error('User add failed:', error);
    }

    handleCloseForm();
  };

  return (
    <div className="p-6">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-200">Job ID</th>
            <th className="py-2 px-4 border-b border-gray-200">Title</th>
            <th className="py-2 px-4 border-b border-gray-200">Company</th>
            <th className="py-2 px-4 border-b border-gray-200">Action</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(job => (
            <tr key={job.id}>
              <td className="py-2 px-4 border-b border-gray-200 cursor-pointer">
                <Link to={`/jobs/edit/${job.id}`}>{job.id}</Link>
              </td>
              <td className="py-2 px-4 border-b border-gray-200">{job.title}</td>
              <td className="py-2 px-4 border-b border-gray-200">{job.company_name1}</td>
              <td className="py-2 px-4 border-b border-gray-200">
                <button
                  className="bg-blue-500 text-white py-1 px-3 rounded"
                  onClick={() => handleOpenForm(job)}
                >
                  Send Email
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedJob && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">Send academic lead email for {selectedJob.title}</h2>
            <form onSubmit={handleSubmit}>
              <label className="block mb-2">
                First Name:
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="w-full px-2 py-1 border rounded mt-1"
                />
              </label>
              <label className="block mb-2">
                Last Name:
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  className="w-full px-2 py-1 border rounded mt-1"
                />
              </label>
              <label className="block mb-2">
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-2 py-1 border rounded mt-1"
                />
              </label>
              {/* <label className="block mb-2">
                Specialty:
                <JobKeywordSearchBlock
                  label="Enter a keyword"
                  customKey="keyword"
                  value1={formData.keyword}
                  onSelect={(keyword) => setFormData(prevData => ({ ...prevData, keyword }))}
                />
              </label> */}
              <label className="block mb-2">
                Is this person an indigenous academic:
                <input
                  type="checkbox"
                  name="indigenousAcademic"
                  checked={formData.indigenousAcademic}
                  onChange={handleChange}
                  className="ml-2"
                />
              </label>
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={handleCloseForm}
                  className="bg-gray-500 text-white py-1 px-3 rounded mr-2"
                >
                  Cancel
                </button>
                <button type="submit" className="bg-blue-500 text-white py-1 px-3 rounded">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AcademicLeadJobs;
