import React, { useEffect, useRef, useState } from 'react';
import {
  useGetOtherContactsQuery,
  useAddOtherContactsMutation,
} from "../store/apiSlice";
import AddOtherContacts from './AddOtherContacts';
import OtherContactsRowEdit from './OtherContactsRowEdit';

const OtherContacts = ({ id }) => {
  const { data, isLoading, isSuccess, isError, error } = useGetOtherContactsQuery({ id });

  const [currentPage, setCurrentPage] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(window.innerWidth < 768 ? 1 : 4);
  const carouselRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setCardsPerPage(window.innerWidth < 768 ? 1 : 4);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (isError) {
    return <p>No sales notes found...</p>;
  }

  if (isSuccess && data?.length > 0) {
    const groupedContacts = [];
    for (let i = 0; i < data.length; i += cardsPerPage) {
      groupedContacts.push(data.slice(i, i + cardsPerPage));
    }

    const handleScroll = (direction) => {
      const newPage = currentPage + direction;
      if (newPage < 0 || newPage >= groupedContacts.length) return;
      setCurrentPage(newPage);
      const scrollAmount = carouselRef.current.clientWidth * direction;
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    };

    return (
      <div className='flex flex-col gap-3 overflow-y-auto h-full'>
        <h2 className='font-bold underline pt-2'>OTHER CONTACTS</h2>
        <AddOtherContacts id={id} />
        <div className="relative">
          <div className="carousel w-full overflow-hidden" ref={carouselRef}>
            <div className="flex transition-transform duration-500 ease-in-out">
              {groupedContacts.map((group, i) => (
                <div className="w-full flex-shrink-0" key={i}>
                  <div className={`grid grid-cols-1 ${cardsPerPage === 4 ? 'md:grid-cols-2 lg:grid-cols-4' : ''} gap-4`}>
                    {group.map((contact, j) => (
                      <OtherContactsRowEdit contact={contact} key={j} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="absolute top-1/2 transform -translate-y-1/2 left-0">
            <button
              onClick={() => handleScroll(-1)}
              className="bg-black btn-circle text-white m-2 opacity-30 hover:opacity-100 transition-opacity duration-300"
            >
              ❮
            </button>
          </div>
          <div className="absolute top-1/2 transform -translate-y-1/2 right-0">
            <button
              onClick={() => handleScroll(1)}
              className="bg-black btn-circle text-white m-2 opacity-30 hover:opacity-100 transition-opacity duration-300"
            >
              ❯
            </button>
          </div>
        </div>
        <div className="flex justify-center w-full py-2 gap-2">
          {groupedContacts.map((_, i) => (
            <span key={i} className={`btn btn-xs ${i === currentPage ? 'btn-active' : ''}`}>{i + 1}</span>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default OtherContacts;
