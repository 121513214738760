import React, { useState, useEffect } from "react";
import { useGetMainSecondOtherContactsQuery } from "../store/apiSlice";

const SalesDatabase = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [showMain, setShowMain] = useState(true);
  const [showSecondary, setShowSecondary] = useState(true);
  const [showOther, setShowOther] = useState(true);
  const [showJobElephant, setShowJobElephant] = useState(false);
  const [jobElephantChecked, setJobElephantChecked] = useState(false);

  const { data, isLoading, isError, refetch } = useGetMainSecondOtherContactsQuery(selectedCountry, {
    skip: !selectedCountry,
  });

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    if (country === "United States") {
      setShowJobElephant(true);
    } else {
      setShowJobElephant(false);
      setJobElephantChecked(false);
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      refetch();
    }
  }, [selectedCountry, refetch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading data.</div>;
  }

  const renderContactInfo = (companyName, contact, type) => {
    if (!contact || 
        ((contact.email === null || contact.email === '') && 
        (contact.phone === null || contact.phone === ''))) {
      return null;
    }
    
    return (
      <tr className={`border-b ${filterContacts(type)}`}>
        <td className="p-2">{companyName}</td>
        <td className="p-2">{type}</td>
        <td className="p-2">{contact.firstname || 'N/A'}</td>
        <td className="p-2">{contact.lastname || 'N/A'}</td>
        <td className="p-2">{contact.jobtitle || 'N/A'}</td>
        <td className="p-2">{contact.email || 'N/A'}</td>
        <td className="p-2">{contact.phone || 'N/A'}</td>
      </tr>
    );
  };

  const filterContacts = (type) => {
    if (type.toLowerCase().includes('main') && !showMain) return 'hidden';
    if (type.toLowerCase().includes('secondary') && !showSecondary) return 'hidden';
    if (type.toLowerCase().includes('other') && !showOther) return 'hidden';
    return '';
  };

  const copyToClipboard = () => {
    let tableText = '';
    const headers = ["Company Name", "Contact Type", "First Name", "Last Name", "Job Title", "Email", "Phone"];
    tableText += headers.join('\t') + '\n';

    const rows = [];

    if (data) {
      Object.keys(data).forEach(employerId => {
        const employerData = data[employerId];
        if (showMain) {
          const mainContact = renderContactText(employerData.company_name, employerData.main_contact, 'Main Contact');
          if (mainContact) rows.push(mainContact);
        }
        if (showSecondary) {
          const secondaryContact = renderContactText(employerData.company_name, employerData.secondary_contact, 'Secondary Contact');
          if (secondaryContact) rows.push(secondaryContact);
        }
        if (showOther && employerData.other_contacts) {
          employerData.other_contacts.forEach(contact => {
            const otherContact = renderContactText(employerData.company_name, contact, 'Other Contact');
            if (otherContact) rows.push(otherContact);
          });
        }
      });
    }

    tableText += rows.join('\n');

    navigator.clipboard.writeText(tableText).then(() => {
      alert('Table copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const renderContactText = (companyName, contact, type) => {
    if (!contact || 
        ((contact.email === null || contact.email === '') && 
        (contact.phone === null || contact.phone === ''))) {
      return '';
    }

    return [
      companyName,
      type,
      contact.firstname || 'N/A',
      contact.lastname || 'N/A',
      contact.jobtitle || 'N/A',
      contact.email || 'N/A',
      contact.phone || 'N/A'
    ].join('\t');
  };

  const filteredData = Object.keys(data || {}).filter(employerId => {
    const employer = data[employerId];
    return !showJobElephant || !jobElephantChecked || employer.job_elephant;
  });

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Select a Country</h2>
      <select
        value={selectedCountry}
        onChange={handleCountryChange}
        className="mb-4 p-2 border border-gray-300 rounded"
      >
        <option value="">Select a country</option>
        <option value="Australia">Australia</option>
        <option value="New Zealand">New Zealand</option>
        <option value="United States">United States</option>
        {/* Add more options as needed */}
      </select>

      {showJobElephant && (
        <div className="mb-4">
          <label className="mr-4">
            <input
              type="checkbox"
              checked={jobElephantChecked}
              onChange={() => setJobElephantChecked(!jobElephantChecked)}
              className="mr-1"
            />
            Job Elephant Clients
          </label>
        </div>
      )}

      <div className="mb-4">
        <label className="mr-4">
          <input
            type="checkbox"
            checked={showMain}
            onChange={() => setShowMain(!showMain)}
            className="mr-1"
          />
          Main Contacts
        </label>
        <label className="mr-4">
          <input
            type="checkbox"
            checked={showSecondary}
            onChange={() => setShowSecondary(!showSecondary)}
            className="mr-1"
          />
          Secondary Contacts
        </label>
        <label>
          <input
            type="checkbox"
            checked={showOther}
            onChange={() => setShowOther(!showOther)}
            className="mr-1"
          />
          Other Contacts
        </label>
      </div>

      {data && (
        <div>
          <h3 className="text-lg font-semibold mb-4">Contacts for {selectedCountry}</h3>
          <button onClick={copyToClipboard} className="mb-4 p-2 bg-blue-500 text-white rounded">Copy to Clipboard</button>
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-4 text-left">Company Name</th>
                <th className="py-3 px-4 text-left">Contact Type</th>
                <th className="py-3 px-4 text-left">First Name</th>
                <th className="py-3 px-4 text-left">Last Name</th>
                <th className="py-3 px-4 text-left">Job Title</th>
                <th className="py-3 px-4 text-left">Email</th>
                <th className="py-3 px-4 text-left">Phone</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {filteredData.map(employerId => {
                const employerData = data[employerId];
                return (
                  <React.Fragment key={employerId}>
                    {showMain && renderContactInfo(employerData.company_name, employerData.main_contact, 'Main Contact')}
                    {showSecondary && renderContactInfo(employerData.company_name, employerData.secondary_contact, 'Secondary Contact')}
                    {showOther && employerData.other_contacts && employerData.other_contacts.length > 0 && (
                      employerData.other_contacts.map((contact, index) => (
                        renderContactInfo(employerData.company_name, contact, 'Other Contact')
                      ))
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SalesDatabase;
