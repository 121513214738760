import { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputBlock3 from "../InputBlock3";
import { setCredentials, isLoggedInOn, isLoggedInOff } from "./authSlice";
import { useRegisterUserMutation } from "./authApiSlice";
// import usePersist from '../../hooks/usePersist'
// import useTitle from '../../hooks/useTitle'
//import PulseLoader from 'react-spinners/PulseLoader'
import { useSelector, useDispatch } from "react-redux";
import { setEmail1, setUserid, setCompany_name, setCountry } from "./authSlice";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import UniSearchBlock from "../UniSearchBlock";
import SignUpBenefits from "../../components/SignUpBenefits";
import SignUpUnis from "../../components/SignUpUnis";
import swal from 'sweetalert';
import { useSendClientAdminRequestEmailMutation } from "../../store/apiSlice";  // Updated import

const SignUp = () => {
  const [sendFormDetails, { isLoading }] = useSendClientAdminRequestEmailMutation(); // Updated hook usage

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      institution: "",
      email: "",
      phone: "",
    },
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        institution: yup.string().required("Institution or Company Name is required"),
        email: yup.string().email("Email is invalid").required("Email is required"),
        phone: yup.string().required("Phone is required"),
      })
    ),
  });

  // const location = useLocation();
  // const [nextPageYN, setNextPageYN] = useState(false);
  // const { from } = location.state || { from: { pathname: "/" } };
  // const productid = useSelector((state) => state.posts.productid);
  // const productQty = useSelector((state) => state.posts.productQty);
  // const addOnYN = useSelector((state) => state.posts.addOnYN);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const [signUpBuy, { isLoading }] = useRegisterUserMutation();
  const onSubmit = async (data) => {
    try {
      const response = await sendFormDetails(data).unwrap();
  
      if (response.message === "Email sent successfully") {
        console.log("Form details sent successfully");
        swal("Request Sent!", "We will contact you shortly.", "success");
      } else {
        console.log("Failed to send form details");
        swal("Error sending request.", "Please try again", "error");
      }
    } catch (err) {
      console.error("An unexpected error occurred", err);
      swal("Error sending request.", "Please try again", "error");
    }
  };
  

  let content;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(true);
  }, []);
  const inputErrClass = "w-full text-xs font-bold text-red-700";
  const onEditorStateChange1 = (company_name, employer_id, Region, country, logo, employerPageURL, clientType) => {
    setValue("company_name1", company_name);
    setValue("employer_id", employer_id || 0);
    setValue("Region", Region);
    setValue("country", country);
    setValue("logo", logo);
    setValue("institution", company_name); // Populate institution with company_name
  }
  content = (
    <>
      <div className="container">
        <div className="flex parent-div items-center justify-center">
          <img
            src="/banner-signup.png"
            alt="Networking & Collaborating"
            className="sign_up_banner flex items-center justify-center p-2"
          />
        </div>
        <section className="px-6 pt-6 mt-2 mx-auto lg:py-0">
          <main>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-1">
              <div className="rounded-lg border text-card-foreground shadow-sm px-10 pb-2 pt-2 bg-slate-50 w-full xl:w-[560px]">
                <img
                  src="/academic-jobs-logo.png"
                  alt="Academic Jobs Logo"
                  className="max-w-[250px] mx-auto"
                />
                <h2 className="text-xl font-semibold mb-2 mt-0">
                  Employer / Recruiter Sign Up
                </h2>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className="flex flex-col gap-2">
                      <div className="relative">
                        <UniSearchBlock
                          register={register}
                          field="employer_name"
                          customKey="company_name"
                          label="Institution or Company Name*"
                          value1={""}
                          onChange={onEditorStateChange1}
                          forceClass="mb-3"
                        />
                        {errors.institution && (
                          <p className={inputErrClass}>{errors.institution.message}</p>
                        )}
                      </div>

                      <InputBlock3
                        type="text"
                        field="firstName"
                        label="First Name*"
                        register={register}
                        errors={errors}
                        forceClass="mb-4"
                      />
                      <InputBlock3
                        type="text"
                        field="lastName"
                        label="Last Name*"
                        register={register}
                        errors={errors}
                        forceClass="mb-4"
                      />
                      <InputBlock3
                        type="email"
                        field="email"
                        label="Email*"
                        register={register}
                        errors={errors}
                        forceClass="mb-4"
                      />
                      <InputBlock3
                        type="tel"
                        field="phone"
                        label="Phone*"
                        register={register}
                        errors={errors}
                        forceClass="mb-4"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 mt-4">
                    <div className="flex justify-between items-center">
                      <button
                        type="submit"
                        className="w-full btn bg-blue-600 text-white mt-4"
                      >
                        Request Access to University Portal
                      </button>
                    </div>
                    <p className="text-center text-sm text-gray-600 m-0 mt-4">
                      Already have an account? please&nbsp;
                      <Link
                        className="text-blue-500 hover:underline"
                        to="/login"
                      >
                        Sign in
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
              <SignUpBenefits />
            </div>
            <SignUpUnis />
          </main>
        </section>
      </div>
    </>
  );

  return content;
};

export default SignUp;
