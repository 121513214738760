import swal from 'sweetalert';

const createInvoiceFunctionality = async (data, createInvoice) => {
  console.log(data);
  try {
    const currentDate = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
    const date = new Date(currentDate).toISOString().split('T')[0];
    
    // Calculate the due date by adding 14 days, still ensuring the San Diego timezone
    const dueDate = new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 14)).toISOString().split('T')[0];
    const discount = {
      type: 'percentage',
      value: 20, // 20% discount
    };
    const response = await createInvoice({
      product: {
        id: 'BJA-1',
        description: `${data.orgnization_name} Classic SINGLE JOB AD on AcademicJobs.com \n '${data.title}' \n Job ${data['08_Invoice_Reference']} \n For: ${data.company_name1} \n Posting Date: ${date}`,
        price: 315,
      },
      customerDetails: {
        name: data['01_First_Name'] + ' ' + data['01_Last_Name'],
        email: data['02_Email'],
        address: data['orgnization_name'],
      },
      currencyCode: 'USD',
      invoiceDetails: {
        address: {
          line1: data['address_line1'],
          line2: data['address_line2'],
          city: data['city'],
          region: data['region'],
          postalCode: data['postalCode'],
          country: data['country'],
        },
        reference: `${data.company_name1} - Job Posting ${data['08_Invoice_Reference']}`,
        dueDate: dueDate,
      },
      discount: discount,
      timezone: 'America/Los_Angeles'
    }).unwrap();

    if (response.status === 200) {
      console.log('Invoice created successfully:', response.data);
    }
  } catch (error) {
    console.error('Error during invoice creation:', error);
  }
};

export default createInvoiceFunctionality;
