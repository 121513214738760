import React, { useState, useEffect, useRef, useMemo } from "react";
import { setEmployer } from "../store/postsSlice";
import { Link } from "react-router-dom";
import UserEdit from "./UserEdit";
import { useSelector, useDispatch } from "react-redux";
const users = [

    {
        "id": 76,
        "firstName": "Mary Rose Joana",
        "portrait": "mary_rose_joana_pecson.jpg"
    },
    {
        "id": 77,
        "firstName": "Joan",
        "portrait": "joan_santos.jpg"
    },
    {
        "id": 182,
        "firstName": "Clarissa",
        "portrait": "ClarissaPacetes.png"
    },
    {
        "id": 79,
        "firstName": "Marcus",
        "portrait": "marcus_ de_leon.jpg"
    },
    {
        "id": 81,
        "firstName": "Jhon Michael",
        "portrait": "jhon_michael_cruz.jpg"
    },
    {
        "id": 82,
        "firstName": "Lexphil",
        "portrait": "lexphil_de_vera.jpg"
    },
    {
        "id": 83,
        "firstName": "Laira Andrea",
        "portrait": "laira_andrea_austria.jpg"
    },
    {
        "id": 184,
        "firstName": "Rencel Velasco",
        "portrait": "RencelVelasco.jpeg"
    },
    {
        "id": 86,
        "firstName": "Don",
        "portrait": "don_fernandez.jpg"
    },
    {
        "id": 4,
        "firstName": "Jarrod",
        "portrait": "jarrod_kanizay.webp"
    }
]
const UsersTask = () => {
    const [userId, setUserId] = useState(83)
    return (
        
        <div className="flex flex-col  gap-2  w-full px-4">
                <p className="text-3xl font-bold text-black shadow-xl px-2 pb-4 mt-4 mb-6">Team Notes</p>

            <div className="justify-center flex flex-wrap gap-2 pb-4">
                {
                    users.map(({ id, firstName, portrait }) => (
                        < div className="card w-[100px] h-[100px] bg-base-100 shadow-xl "
                            onClick={() => {
                                setUserId(id)
                            }}
                        >
                            <img
                                src={`https://academicjobs.s3.amazonaws.com/img/users/${portrait}` || '/favicon.png'}
                                alt={firstName}
                                className=" w-[100px] h-[100px] object-contain rounded-md bg-white"
                            />
                        </div>
                    ))
                }
            </div>
            <UserEdit id={userId} />
            {/* <Link className=" btn btn-md  max-w-sm whitespace-nowrap" to="/signup">
                Sign Up
            </Link> */}
        </div>)
};
export default UsersTask;
