import { useState } from 'react';
import {
  useGetJobBoardsQuery,
  useCreateJobBoardMutation,
  useDeleteJobBoardMutation,
  useUpdateJobBoardMutation,
} from '../store/apiSlice'; // Adjust the import path as necessary
import Select from 'react-select';
import JobKeywordSearchBlock from "./MultiSelectJobKeywordSearchBlock";
import TopKeywords from '../components/TopKeywords';
import CountryList from '../data/CountryList.json';
import CurrencyList from '../data/CurrencyList.json'; // Import your currency list JSON file

const JobBoards = () => {
  const { data: jobBoards, error, isLoading } = useGetJobBoardsQuery();
  const [createJobBoard] = useCreateJobBoardMutation();
  const [deleteJobBoard] = useDeleteJobBoardMutation();
  const [updateJobBoard] = useUpdateJobBoardMutation();

  const [formData, setFormData] = useState({
    id: null,
    name: '',
    url: '',
    specialty: [],
    servicedCountries: [],
    monthlyTraffic: 0,
    topKeywords: [],
    jobAdPrice: 0,
    currency: '',
    ranking: 0,
    notes: ''
  });

  // Transforming data for react-select
  const countryOptions = CountryList.map(country => ({
    label: country.name,
    value: country.code
  }));

  const currencyOptions = CurrencyList.map(currency => ({
    label: `${currency.name} (${currency.code})`,
    value: currency.code
  }));

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    if (Array.isArray(selectedOption)) {
      // For multi-select inputs like servicedCountries
      setFormData({
        ...formData,
        [actionMeta.name]: selectedOption.map(option => option.value)
      });
    } else {
      // For single-select inputs like currency
      setFormData({
        ...formData,
        [actionMeta.name]: selectedOption ? selectedOption.value : ''
      });
    }
  };

  const handleSpecialtySelect = (selectedSpecialties) => {
    setFormData({ ...formData, specialty: selectedSpecialties });
  };

  const handleKeywordChange = (newKeywords) => {
    setFormData({ ...formData, topKeywords: newKeywords });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.id) {
        await updateJobBoard({
          id: formData.id,
          ...formData,
        }).unwrap();
      } else {
        await createJobBoard({
          ...formData,
          specialty: formData.specialty,
          servicedCountries: formData.servicedCountries,
          topKeywords: formData.topKeywords,
        }).unwrap();
      }
      setFormData({
        id: null,
        name: '',
        url: '',
        specialty: [],
        servicedCountries: [],
        monthlyTraffic: 0,
        topKeywords: [],
        jobAdPrice: 0,
        currency: '',
        ranking: 0,
        notes: ''
      });
    } catch (error) {
      console.error('Error creating or updating job board:', error);
      alert('Error creating or updating job board. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteJobBoard(id).unwrap();
    } catch (error) {
      console.error('Error deleting job board:', error);
    }
  };

  const handleEdit = (board) => {
    setFormData({
      id: board.id,
      name: board.name,
      url: board.url,
      specialty: board.specialty,
      servicedCountries: board.servicedCountries,
      monthlyTraffic: board.monthlyTraffic,
      topKeywords: board.topKeywords,
      jobAdPrice: board.jobAdPrice,
      currency: board.currency,
      ranking: board.ranking,
      notes: board.notes,
    });
  };

  const handleCancelEdit = () => {
    setFormData({
      id: null,
      name: '',
      url: '',
      specialty: [],
      servicedCountries: [],
      monthlyTraffic: 0,
      topKeywords: [],
      jobAdPrice: 0,
      currency: '',
      ranking: 0,
      notes: ''
    });
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Niche Job Boards</h1>
      <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200 overflow-x-auto">
        <thead>
          <tr>
            {['Name', 'URL', 'Specialties', 'Serviced Countries', 'Monthly Traffic', 'Top Keywords', 'Job Ad Price', 'Currency', 'Ranking', 'Notes', 'Actions'].map(header => (
              <th key={header} className="px-4 py-2 border-b border-gray-200 text-left">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {jobBoards.map(board => (
            <tr key={board.id} className="hover:bg-gray-100">
              <td className="px-4 py-2 border-b border-gray-200">{board.name}</td>
              <td className="px-4 py-2 border-b border-gray-200"><a href={board.url} className='text-blue-500 underline' target='_blank'>{board.url}</a></td>
              <td className="px-4 py-2 border-b border-gray-200">
                <ul className="list-disc pl-5">
                  {board.specialty.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </td>
              <td className="px-4 py-2 border-b border-gray-200">
                <ul className="list-disc pl-5">
                  {board.servicedCountries.map((item, index) => (
                    <li key={index}>{countryOptions.filter(x => x.value === item).map(x => x.label)}</li>
                  ))}
                </ul>
              </td>
              <td className="px-4 py-2 border-b border-gray-200">{board.monthlyTraffic}</td>
              <td className="px-4 py-2 border-b border-gray-200">
                <ul className="list-disc pl-5">
                  {board.topKeywords.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </td>
              <td className="px-4 py-2 border-b border-gray-200">{board.jobAdPrice}</td>
              <td className="px-4 py-2 border-b border-gray-200">{board.currency}</td>
              <td className="px-4 py-2 border-b border-gray-200">{board.ranking}</td>
              <td className="px-4 py-2 border-b border-gray-200">{board.notes}</td>
              <td className="px-4 py-2 border-b border-gray-200">
                <button
                  onClick={() => handleEdit(board)}
                  className="px-2 py-1 bg-green-500 text-white rounded-md mr-2 mb-2"
                >
                  Update
                </button>
                <button
                  onClick={() => handleDelete(board.id)}
                  className="px-2 py-1 bg-red-500 text-white rounded-md"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className='mt-6 border-2 p-4 rounded-xl'>
        <h2 className="text-xl font-bold mb-4">{formData.id ? `Updating - ${formData.name}` : 'Create New Job Board'}</h2>
        <form className="grid grid-cols-1 sm:grid-cols-2 gap-4" onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-medium">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium">URL:</label>
            <input
              type="text"
              name="url"
              value={formData.url}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Specialties:</label>
            <JobKeywordSearchBlock
              label="Enter a keyword"
              initialSelectedKeywords={formData.specialty}
              onSelect={handleSpecialtySelect}
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Serviced Countries:</label>
            <Select
              isMulti
              name="servicedCountries"
              options={countryOptions}
              className="basic-multi-select"
              classNamePrefix="py-[2px] select"
              onChange={handleSelectChange}
              value={countryOptions.filter(option => formData.servicedCountries.includes(option.value))}
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Monthly Traffic:</label>
            <input
              type="number"
              name="monthlyTraffic"
              value={formData.monthlyTraffic}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Top Keywords:</label>
            <TopKeywords
              initialKeywords={formData.topKeywords}
              onKeywordsChange={handleKeywordChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Job Ad Price:</label>
            <input
              type="number"
              name="jobAdPrice"
              value={formData.jobAdPrice}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Job Ad Currency:</label>
            <Select
              name="currency"
              options={currencyOptions}
              className="basic-single-select"
              classNamePrefix="select"
              onChange={handleSelectChange}
              value={currencyOptions.find(option => option.value === formData.currency)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Ranking:</label>
            <input
              type="number"
              name="ranking"
              value={formData.ranking}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Notes:</label>
            <input
              type="text"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="sm:col-span-2 flex justify-end gap-2 items-end space-y-2">
            <button type="submit" className="px-4 py-2 bg-green-500 text-white rounded-md">
              {formData.id ? 'Update' : 'Create'}
            </button>
            {formData.id && (
              <button type="button" className="px-4 py-2 bg-red-500 text-white rounded-md" onClick={handleCancelEdit}>
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default JobBoards;
