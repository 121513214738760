import { useGetEmployerQuery } from "../store/apiSlice";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { setEmployer } from "../store/postsSlice";
import EmployerAddEdit from "./EmployerAddEdit";
import { useSelector, useDispatch } from "react-redux";
const EditEmployerProfile1 = ({ id = 7 }) => {
  const dispatch = useDispatch();
  const employer = useSelector((state) => state.posts.employer);
  console.log("EditEmployerProfile", id);
  const { data, isLoading, isSuccess } = useGetEmployerQuery(id);
  let content;
  if (isLoading) {
    content = "Loading";
  }
  if (data) {
    console.log("data=========", data);
    //dispatch(setEmployer(data))
    content = (
      <div className="overflow-y w-full">
        {employer?.company_name && (
          <div className="flex flex-col gap-2 p-4 bg-slate-100 rounded-t-xl mx-4">
            <div className="flex flex-wrap justify-start gap-1 items-center align-middle">
              <ul className="flex justify-start items-end gap-4 px-1">
                <div className="card w-[100px] h-[100px] bg-base-100 shadow-xl">
                  <div className="w-[100px] h-[100px] grid place-items-center">
                    <img
                      src={
                        employer?.logo
                          ? `https://academicjobs.s3.amazonaws.com/img/university-logo/${employer?.logo}`
                          : "/favicon.png"
                      }
                      alt={`${employer?.company_name}`}
                      className="object-contain rounded-md bg-white"
                    />
                  </div>
                </div>
              </ul>
              <div className="md:text-4xl text-xl font-black text-center font-serif overflow-hidden text-ellipsis whitespace-nowrap max-w-full">
                {employer?.company_name}
              </div>
            </div>
            <div className="text-2xl text-orange-600 font-black text-left font-serif px-4">
              Our #1 Client
            </div>

            <div className="flex mr-4">
              <Link
                to={`/employers/${employer?.company_name
                  ?.replace(/\W+/g, "-")
                  .toLowerCase()}/${employer?.employer_id}/`}
                className="btn w-1/3"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Employer Profile
              </Link>
              <Link
                to={data?.employerPageURL}
                className="btn  ml-2 w-1/3"
                target="_blank"
                rel="noopener noreferrer"
              >
                Employer Job Page
              </Link>
              <a
                to={data?.employerPageURL}
                className="btn  ml-2 w-1/3"
                target="_blank"
                rel="noopener noreferrer"
              >
                Edit Employer profile
              </a>
            </div>
            <Link to={`/post-a-job/`} className="btn bg-amber-500">
              Post A Job
            </Link>

            {/* <div className="bg-slate-100 open:bg-[amber-200] duration-300  mb-4 full width   mt-4">
              <p className="bg-inherit text-lg  px-4 font-bold underline pb-2">
                Key Client Information
              </p>
              <p className="bg-inherit  cursor-pointer px-4 font-bold  text-blue-800">
                Job Board Partners:{" "}
              </p>

              <p className="  pl-6  mb-4">
                dropdown options i.e. PageUp, Workday
              </p>

              <p className="text-blue-800  font-bold   px-4  ">
                Our Key Contact:{" "}
              </p>
              <p className=" pl-6  px-4  pb-4">
                Kayla Wilson - 0411 382 893 - kayla@academicjobs.com
              </p>
              <p className="text-blue-800 font-bold  px-4  ">
                Other Org Contact:{" "}
              </p>
              <ul className="list-none  flex flex-col gap-1 px-4">
                <li className=" pl-6 relative before:block before:text-green-500 before:absolute before:left-0">
                  Mary Smith - 0411 382 893 - kayla@academicjobs.com
                </li>

                <li className=" pl-6 relative  before:block before:text-green-500 before:absolute before:left-0 ">
                  <span className="">
                    Dave Jeffery - 0211 372 193 - dave@academicjobs.com
                  </span>
                </li>
                <li className="pl-6 relative before:block before:text-green-500 before:absolute before:left-0 ">
                  Tom Le - 0311 482 883 - tom@academicjobs.com
                </li>
              </ul>

              <p className="text-blue-800 font-bold  px-4  mt-4 mb-4">
                Virtual Recruitment Team: 105{" "}
                <span className="underline pl-4">View List</span>
              </p>

              <span className="font-bold mt-4 px-4 text-blue-800 relative  before:block before:text-green-500 before:absolute before:left-0 ">
                Affiliated recruitment agencies:{" "}
              </span>

              <p className="  pl-6  mb-4">JobElephant</p>

              <span className="font-bold mt-4 px-4 text-blue-800 relative  before:block before:text-green-500 before:absolute before:left-0 ">
                Job board Rankings{" "}
              </span>
            </div> */}
          </div>
        )}
        <EmployerAddEdit employer={data} />
      </div>
    );
  }

  // { !isLoading ? <EmployerAddEdit employer={data} /> :  }
  return content;
};
export default EditEmployerProfile1;
