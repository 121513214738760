import { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputBlock3 from "../InputBlock3";
import { setCredentials, isLoggedInOn, isLoggedInOff } from "./authSlice";
import { useRegisterUserMutation } from "./authApiSlice";
// import usePersist from '../../hooks/usePersist'
// import useTitle from '../../hooks/useTitle'
//import PulseLoader from 'react-spinners/PulseLoader'
import { useSelector, useDispatch } from "react-redux";
import { setEmail1, setUserid, setCompany_name, setCountry } from "./authSlice";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import UniSearchBlock from "../UniSearchBlock";
import SignUpBenefits from "../../components/SignUpBenefits";
const SignUp = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    // resolver: yupResolver(validationSchema),
    defaultValues: {
      // postedBy: userInfo.id,
      username: "",
      email: "",
      password: "",
      // employer_name: userInfo.employer_name,
      // employer_id: userInfo.employer_id,
    },
  });
  const location = useLocation();
  const [nextPageYN, setNextPageYN] = useState(false);
  const { from } = location.state || { from: { pathname: "/" } };
  const productid = useSelector((state) => state.posts.productid);
  const productQty = useSelector((state) => state.posts.productQty);
  const addOnYN = useSelector((state) => state.posts.addOnYN);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signUpBuy, { isLoading }] = useRegisterUserMutation();
  const onSubmit1 = async (data) => {
    //e.preventDefault();
    console.log("data", data);
    // if (!(userInfo.jobCredits > 0)) {
    //   setNoCreditsYN(true);
    // } else {
    //   const response = await postAJob(data);
    //   console.log(response);
    //   if (response) {
    //     setJobStatus("Job POSTED!")
    //     //submitRef.current.innerText = "Job POSTED!";
    //     setTimeout(() => {
    //       setJobStatus("Post Job")
    //       //submitRef.current.innerText = "Post Job";
    //     }, 5000);
    //   }
    // }
  };
  const onSubmit = async (data) => {
    console.log("========data", data);
    try {
      const { accessToken } = await signUpBuy({
        ...data,
        productid,
        productQty,
      }).unwrap();
      dispatch(setCredentials({ accessToken }));
      
      navigate("/admin-home");
    } catch (err) {
      if (err.data?.message) {
      } else if (err.status === 400) {
      } else if (err.status === 401) {
      } else {
      }
    }
  };
  let content;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(true);
  }, []);
  const inputErrClass = "w-full text-xs font-bold text-red-700";
  // const handleCompanyNameInput = (newValue, flag) => setCompany_name(newValue);
  const onEditorStateChange1 = (company_name, employer_id, Region, country, logo, employerPageURL, clientType) => {
    // console.log("======onEditorStateChange1=====", company_name, employer_id, Region, country, logo)
    setValue("company_name1", company_name);
    setValue("employer_id", employer_id || 0);
    setValue("Region", Region);
    setValue("country", country);
    setValue("logo", logo);
    // dispatch(setEmployer({ company_name, employer_id, logo, employerPageURL, clientType }))
    // if (employer_id) getCampuses({ id: employer_id })
  }
  content = (
    <>
      <div className="container">
        <h1 className="sign_up text-center mt-8">Find your best</h1>
        <h2 className="sign_up text-center mb-32 text-slate-600">
          Exclusive access to the best communities,
          <br />
          opportunities and prospects in one spot.
        </h2>
        <section className="px-6 pt-6 mx-auto lg:py-0">
          <main className="">
            {/* <p ref={errRef} className={errClass} aria-live='assertive'>{errMsg}</p> */}
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-16">
              <div className="rounded-lg border text-card-foreground shadow-sm px-10 pb-8 pt-2 bg-slate-50 w-full xl:w-[560px]">
                <h2 className="text-xl font-semibold mb-4">
                  Employer / Recruiter Sign Up
                </h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className="flex flex-col gap-2">
                      <div className="relative">
                        <UniSearchBlock
                          register={register}
                          field="employer_name"
                          customKey="company_name"
                          label="Institution or Company Name*"
                          value1={""}
                          onChange={onEditorStateChange1}
                          forceClass="mb-3"
                        />
                        {errors.company_name && (
                          <p className={inputErrClass}>{errors.company_name}</p>
                        )}
                      </div>
                      <InputBlock3
                        type="text"
                        field="firstName"
                        label="First Name*"
                        register={register}
                        errors={errors}
                        forceClass="mb-4"
                      />
                      <InputBlock3
                        type="text"
                        field="lastName"
                        label="Last Name*"
                        register={register}
                        errors={errors}
                        forceClass="mb-4"
                      />
                      <InputBlock3
                        type="text"
                        field="email"
                        label="Email*"
                        register={register}
                        errors={errors}
                        forceClass="mb-4"
                      />
                      <InputBlock3
                        type="password"
                        field="password"
                        label="Password*"
                        register={register}
                        errors={errors}
                        forceClass="mb-4"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 mt-4">
                    <div className="flex justify-between items-center">
                      <button
                        type="submit"
                        className="w-full btn bg-blue-600 text-white mt-4 "
                      >
                        Sign Up
                      </button>
                    </div>
                    <p className="text-center text-sm text-gray-600 m-0 mt-4">
                      Already have an account? please&nbsp;
                      <Link
                        className="text-blue-500 hover:underline"
                        to="/login"
                      >
                        Sign in
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
              <SignUpBenefits />
            </div>
          </main>
        </section>
      </div>
    </>
  );
  return content;
};
export default SignUp;
