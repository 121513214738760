import { useGetSalesLeadsListQuery } from "../store/apiSlice";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { setEmployer } from "../store/postsSlice";
import EmployerAddEdit from "./EmployerAddEdit";
import { useSelector, useDispatch } from "react-redux";
const SalesLeadsList = ({ id = 7 }) => {
  const dispatch = useDispatch();
  const employer = useSelector((state) => state.posts.employer);
  console.log("EditEmployerProfile", id);
  const { data, isLoading, isSuccess } = useGetSalesLeadsListQuery({ id });
  let content;
  if (isLoading) {
    content = "Loading";
  }
  if (data) {
    console.log("data=========", data);
    //dispatch(setEmployer(data))
    content = (
      <div className="overflow-y-auto h-[300px] w-full mb-8">
        {
          <table className="border border-collapse border-gray-400">
            <thead>
              <tr>
                <th className="border p-2">First Name</th>
                <th className="border p-2">Last Name</th>
                <th className="border p-2">Title</th>
                <th className="border p-2">Email</th>
                <th className="border p-2">Phone</th>
              </tr>
            </thead>

              <tbody>
              
              {data.map(({ firstName1, lastName1, jobTitle1, email1, phone1 }, i) => (
                  <tr key={i} className="border">
                    <td className="border p-2">{firstName1}</td>
                  <td className="border p-2">{lastName1}</td>
                  <td className="border p-2">{jobTitle1}</td>
                  <td className="border p-2">{email1}</td>
                  <td className="border p-2">{phone1}</td>
              
                  </tr>
                ))}
              </tbody>
          </table>
    }
      
      </div>
    );
  }

  // { !isLoading ? <EmployerAddEdit employer={data} /> :  }
  return content;
};
export default SalesLeadsList;
