import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateInfo } from "../store/postsSlice";
import {
  useGetUserSuggestionsQuery,
} from "../store/apiSlice";
import { setJob, setId, setEmployer } from '../store/postsSlice'

const UniSearchBlock = ({ field, register, country, label, customKey, value1, onChange, forceClass, onSelect }) => {
  const countryRef = useRef('');
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(value1 || '');
  const { data: suggestions = [] } = useGetUserSuggestionsQuery({ query, country: country  || "" }, {
  });
  const handleInputClick = () => {
    setShowSuggestions(true);
    
  };
  const handleSuggestionClick = (company_name, id, firstName, lastName, email,orgnization_name) => {

    onChange(company_name, id ||0, firstName, lastName, email, orgnization_name);
    onSelect && onSelect(company_name, id || 0, firstName, lastName, email, orgnization_name);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleInputChange = (e) => {
    //inputRef.current.value = e.target.value;
    setShowSuggestions(true);
    setQuery(e.target.value)
    onChange(e.target.value, 0,"","","");
  };
  useEffect(() => {
    //inputRef.current.value = value1 || '';
    setQuery(value1 || '')
  }, [value1]);
  return (
    <div className="w-full">
      <input
        {...register(field)}
        ref={inputRef}
        type="text"
        value={query}
        onChange={handleInputChange}
        onClick={handleInputClick}
        //placeholder="Type at least 3 characters..."
        // className="w-full font-normal py-1 px-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        className={`w-full input input-bordered ${forceClass}`}
        autoComplete="one-time-code"
        name={customKey}
        placeholder={customKey}
        //onChange={handleInputChange}
      />
      {showSuggestions && (
        <table className="mt-2 w-full border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 px-4 border">Company Name</th>
              <th className="py-2 px-4 border">ID</th>
              <th className="py-2 px-4 border">First Name</th>
              <th className="py-2 px-4 border">Last Name</th>
              <th className="py-2 px-4 border">Email</th>
              <th className="py-2 px-4 border">Organization Name</th>
            </tr>
          </thead>
          <tbody>
            {suggestions.map(({ company_name, id, firstName, lastName, email, orgnization_name }, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSuggestionClick(company_name, id, firstName, lastName, email, orgnization_name)}
              >
                <td className="py-2 px-4 border">{company_name}</td>
                <td className="py-2 px-4 border">{id}</td>
                <td className="py-2 px-4 border">{firstName}</td>
                <td className="py-2 px-4 border">{lastName}</td>
                <td className="py-2 px-4 border">{email}</td>
                <td className="py-2 px-4 border">{orgnization_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
)}
    </div>
  );
};
export default UniSearchBlock