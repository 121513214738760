import React from "react";
import { Link } from "react-router-dom";

export default function EmployerStats({
  iconClass = "",
  employerStats = [],
  organisation,
}) {
  let stats = [];
  if (employerStats.length === 0) {
    stats = [
      { name: "Likes", amount: 0, icon: "/icons/heart.svg" },
      {
        name: "Job Apply Hits",
        amount: 0,
        icon: "/icons/job-apply-hits.svg",
      },
      { name: "Job Views", amount: 0, icon: "/icons/job-views.svg" },
      {
        name: "Talent Pool",
        amount: 0,
        icon: "/icons/talent-pool-person.svg",
      },
    ];
  } else {
    stats = employerStats;
  }

  return (
    <div className="w-full bg-slate-100 p-4 rounded-lg grid grid-cols-2 sm:grid-cols-4 gap-4">
      {stats.map((stat, index) => (
        <div key={index} className="flex text-xs items-center gap-1">
          <span className="block text-xs font-bold">{stat.amount}</span>
          <img
            src={stat.icon || "/icons/heart.svg"}
            alt="icon"
            width={20}
            height={20}
            className={`${iconClass}`}
          />
          <span className="block">{stat.name}</span>
        </div>
      ))}
    </div>
  );
}
