import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RegionalPosting from './RegionalPosting';
import { useDeductJobCreditsMutation, useUpdateJobMutation } from "../store/apiSlice";

const JobList1 = ({ endOfJobs, data, nextPage }) => {
  const navigate = useNavigate();
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [jobToActivate, setJobToActivate] = useState(null);
  const [deductJobCredit] = useDeductJobCreditsMutation();
  const [updateJob] = useUpdateJobMutation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleEditJob = (job) => {
    navigate("/post-job-employer/", { state: { job } });
  };

  const userInfo = useSelector((state) => state.auth.userInfo);
  const jobPostCreditCount = useSelector((state) => state.posts.jobCredits);

  const navigateToPaymentPage = () => {
    navigate('/choose-job-pack');
  };

  const openActivationModal = (job) => {
    setShowActivationModal(true);
    setJobToActivate(job);
  };

  const handleButtonClick = (job) => {
    if (jobPostCreditCount === 0) {
      navigateToPaymentPage();
    } else {
      openActivationModal(job);
    }
  };

  const handleCloseModal = () => {
    setShowActivationModal(false);
    setJobToActivate(null);
  };

  const handleConfirmActivation = async (job) => {
    setLoading(true);
    setError(null);

    try {
      await deductJobCredit({ userId: userInfo.id }).unwrap();
      await updateJob({ id: job.id, headlineOnly: false });
      handleCloseModal();
    } catch (error) {
      setError('There was an error activating the job. Please try again.');
      console.error('Error during job activation:', error);
    } finally {
      setLoading(false);
    }
    window.location.reload();
  };

  const content = data.map((job, index) => {
    const {
      headlineOnly,
      email1,
      activation_date,
      createdAt,
      activeYN,
      employer_id,
      title,
      location,
      expiration_date,
      how_to_apply,
      logo,
      company_name,
      featured,
      id,
      description,
      salary_from,
      salary_to,
      postRegions
    } = job;

    const currentDate = new Date();
    const expirationDate = new Date(expiration_date);

    return (
      <div className={`bg-white border mt-2 relative border-gray-200 p-4 mb-8 rounded-xl shadow-lg block font-bold`} key={index}>
        <div className="flex justify-between">
          <div className="flex items-center pb-2">
            <div className="flex-1">
              <span className="">
                <a className="text-gray-500 text-xl mr-4 font-bold leading-tight hover:underline cursor-pointer" href={`https://www.academicjobs.com/jobs/myjob/${id}`} target="_blank">
                  {title}
                </a>
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-1.5 mx-auto md:mx-0 mb-2">
            {new Date(expiration_date) > new Date() && expiration_date && headlineOnly ? (
              <button
                className="btn-sm btn-accent rounded-full leading-tight"
                onClick={() => handleButtonClick(job)}
              >
                Activate Full Ad Now
              </button>
            ) : null}
          </div>
        </div>
        <div className="flex items-center gap-2 flex-wrap sm:flex-nowrap">
          {!headlineOnly && (
            <div className="w-full sm:w-auto">
              <button className="btn text-sky-600 hover:underline text-xs cursor-pointer w-full sm:w-auto" onClick={() => handleEditJob(job)}>
                Edit Job
              </button>
            </div>
          )}
          <div className="w-full sm:w-auto">
            <a className="btn text-amber-500 hover:underline text-xs cursor-pointer w-full sm:w-auto" href={`https://www.academicjobs.com/jobs/myjob/${id}?mode=share`} target="_blank">
              Academic Connect
            </a>
          </div>
          <RegionalPosting postRegions={postRegions} id={id} />
          <div className="text-sm text-gray-700 font-light mr-auto"></div>
          <div className="flex text-gray-700 text-sm font-light flex-col">
            {headlineOnly ? <span>'Headline Job' Only</span> : <span className="text-xs text-emerald-700 font-semibold rounded-full mr-auto">Full Job</span>}
            <time>
              Posted:{" "}
              <span className="text-gray-400">
                {new Date(activation_date).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}
              </span>
            </time>
            {expiration_date && (
              <time>
                Exp.{" "}
                <span className="text-gray-400">
                  {expiration_date && `${new Date(expiration_date).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}`}
                </span>
              </time>
            )}
          </div>
        </div>
        {expirationDate < currentDate && expiration_date ? (
          <div className="absolute text-xs bg-red-700 text-white font-semibold rounded-full px-3 pt-[3px] pb-[3px] top-[-10px] z-10 right-3">Expired</div>
        ) : expirationDate > currentDate && featured ? (
          <div className="text-xs bg-emerald-500 font-semibold rounded-full px-2 py-[1px] text-white absolute top-[-8px] z-10 right-3">
            <span>Featured</span>
          </div>
        ) : null}
      </div>
    );
  });

  return (
    <div className="overflow-y w-full">
      {content}
      {showActivationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Confirm Activation</h2>
            <p className="mb-4">Are you sure you want to activate this job post? This will require 1 job credit.</p>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className="flex justify-end space-x-4">
              <button className="btn btn-primary" onClick={() => handleConfirmActivation(jobToActivate)} disabled={loading}>
                {loading ? 'Processing...' : 'Yes'}
              </button>
              <button className="btn " onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobList1;
