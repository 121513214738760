import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import SearchResults1 from "./SearchResults1";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { setJobCredits } from "../store/postsSlice";
import UserSearchBlock1 from "./UserSearchBlock1";
import UniSearchBlock from "./UniSearchBlock";
import { useForm } from "react-hook-form";
import SearchResultsEmployers from "./SearchResultsEmployers";
import { setJob, setId, setEmployer } from '../store/postsSlice'
function Users(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employerID, setEmployerID] = useState(3173)
  const region = useSelector((state) => state.posts.region);
  const userInfo = useSelector((state) => state.auth.userInfo);
  let content, content1;
  const { register, handleSubmit, setValue, watch, formState: { errors }, setError } = useForm()
  useEffect(() => {
    register("employer_name", { required: false });
  }, [register]);
  let { expired } = useParams();
  const onEditorStateChange1 = (company_name, id, Region, country) => {
    console.log("============222222=========================", company_name, id, Region, country)
    setEmployerID(id)
    dispatch(setEmployer({ company_name, employer_id: id }))
    //setValue("description", editorState);
  };
  const onSelectUniSearch = (company_name, id, Region, country) => {
    console.log("============33333=========================", company_name, id, Region, country)
    setEmployerID(id)
    dispatch(setEmployer({ company_name, employer_id: id }))
    navigate(`/employers-center/${id}/`);
    //setValue("description", editorState);
  };
  return (
    <div className=" flex  flex-col px-6  gap-8">
      <div className="flex justify-between w-full gap-8">
        <div className="w-full">
          <div className="flex gap-4">
            <div className="w-2/3">
              <UserSearchBlock1
                country=""
                register={register}
                field="employer_name"
                customKey="Search all employers - Type in University Name"
                label="Search all employers"
                value1=""
                forceClass="input-lg mx-0 "
                onChange={onEditorStateChange1}
                onSelect={onSelectUniSearch}
              />
            </div>
            <div className="w-1/3">
              <a class="btn w-full  bg-amber-500 py-8 pb-10" href="/create-employer/">Add User</a>
            </div>
          </div>
          <h2 className="text-xl mt-8 font-bold">Recently Added Users</h2>
          {/* <SearchResultsEmployers /> */}
        </div>
      </div>
    </div>
  );
}
export default Users;
